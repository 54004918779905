/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Image, Container, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './viewUser.module.scss';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import envValues from '../../enviornment';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { Entry } from '../../redux/entrySlice';

function ViewUsers() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let userId;
  const dispatch = useDispatch();
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [roles, setRole] = useState([]);
  const [submiting, setSubmitting] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const dateFormat = getCookies('dateFormat');
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/${userId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res?.data?.user);
          setRole(res.data.user.role);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const getAppointments = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/client/${userId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setAppointments(res?.data?.users);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    getUserDetails();
    getAppointments();
    setDashboardHeader('User Details');
  }, []);

  function deleteUser() {
    const data = {};
    setSubmitting(true);
    setError(null);
    const actionData = {
      actionUrl: `user/${params.userId}`,
      actionMethod: 'delete',
    };
    dispatch(Entry(actionData)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(`/user`);
        toast.success('User deleted successfully');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        toast.error('Error');
      } else {
        // navigate(-1);
        setError(resp.payload.message);
        toast.error('Error');
      }
    });
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const modify = () => {
    setShowModal(true);
  };
  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        <div className="form_head">
          <div
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={`${styles.arrowback} backBtn`}
          >
            <svg
              className="mr-1"
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 6.00003H3.64L7.27 1.64003C7.43974 1.43581 7.5214 1.17253 7.49702 0.908108C7.47264 0.643682 7.34422 0.39977 7.14 0.230032C6.93578 0.060293 6.6725 -0.0213689 6.40808 0.0030108C6.14365 0.0273905 5.89974 0.155815 5.73 0.360032L0.73 6.36003C0.696361 6.40776 0.666279 6.45789 0.64 6.51003C0.64 6.56003 0.64 6.59003 0.57 6.64003C0.524674 6.75469 0.500941 6.87674 0.5 7.00003C0.500941 7.12332 0.524674 7.24537 0.57 7.36003C0.57 7.41003 0.57 7.44003 0.64 7.49003C0.666279 7.54217 0.696361 7.59231 0.73 7.64003L5.73 13.64C5.82402 13.7529 5.94176 13.8437 6.07485 13.9059C6.20793 13.9681 6.35309 14.0003 6.5 14C6.73365 14.0005 6.96009 13.9191 7.14 13.77C7.24126 13.6861 7.32496 13.583 7.38631 13.4666C7.44766 13.3503 7.48546 13.223 7.49754 13.092C7.50961 12.961 7.49573 12.829 7.45669 12.7034C7.41764 12.5777 7.3542 12.4611 7.27 12.36L3.64 8.00003H15.5C15.7652 8.00003 16.0196 7.89467 16.2071 7.70714C16.3946 7.5196 16.5 7.26525 16.5 7.00003C16.5 6.73482 16.3946 6.48046 16.2071 6.29292C16.0196 6.10539 15.7652 6.00003 15.5 6.00003Z"
                fill=""
              />
            </svg>
            Back
          </div>
        </div>
        <div className={styles.action_btns}>
          <DeleteModal show={showModal} handleClose={handleCloseModal} onDelete={deleteUser} />
          <Button className={`${styles.deleteBtn} ml-1`} onClick={(e) => modify(e)}>
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 4.25L3.1875 19.25C3.23203 20.1167 3.8625 20.75 4.6875 20.75H13.3125C14.1408 20.75 14.7595 20.1167 14.8125 19.25L15.75 4.25"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M0.75 4.25H17.25H0.75Z" fill="black" />
              <path
                d="M0.75 4.25H17.25"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M6 4.25V2.375C5.99957 2.22715 6.02837 2.08066 6.08475 1.94397C6.14114 1.80729 6.22399 1.6831 6.32854 1.57854C6.43309 1.47399 6.55728 1.39114 6.69397 1.33476C6.83066 1.27838 6.97714 1.24957 7.125 1.25H10.875C11.0229 1.24957 11.1693 1.27838 11.306 1.33476C11.4427 1.39114 11.5669 1.47399 11.6715 1.57854C11.776 1.6831 11.8589 1.80729 11.9152 1.94397C11.9716 2.08066 12.0004 2.22715 12 2.375V4.25M9 7.25001V17.75M5.625 7.25001L6 17.75M12.375 7.25001L12 17.75"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Delete
          </Button>
        </div>
        <div
          className={`${styles[formthemeStyle]} ${styles.profile_view_container} profile_view_container`}
          id={styles.addform}
        >
          {loading ? (
            <SpinnerDotted
              style={{
                color: '#205440',
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
              }}
            />
          ) : (
            <Row>
              <Col md={3}>
                <div className={styles.profilepicdiv}>
                  <Image
                    src={details?.image ? details?.image : '/favicon/avatar-png.jpg'}
                    alt="logo"
                    className={styles.profilepic}
                  />
                </div>
              </Col>
              <Col md={3}>
                <span className={styles.title}>Name</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>{details.name}</p>
                </div>
                <span className={styles.title}>Email</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>{details.email}</p>
                </div>
                <span className={styles.title}>Phone Number</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>{details.phone}</p>
                </div>
              </Col>
              <Col md={3}>
                <span className={styles.title}>Total number of appointments scheduled</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>{appointments?.length ?? 0}</p>
                </div>
                {/* <span className={styles.title}>DOB</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>
                    {details.dateOfBirth
                      ? DateFormatter(details.dateOfBirth, dateFormat || 'toDateString')
                      : 'NA'}
                  </p>
                </div> */}
              </Col>
              <Col md={3}>
                {/* <span className={styles.title}>Status</span>
                <div className={styles[viewformStyle]} id={styles.empdiv}>
                  <p className={styles.empname}>{details.active ? 'Active' : 'InActive'}</p>
                </div> */}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
