import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line max-len
function EmailInput({
  fieldName,
  register,
  errors,
  label,
  placeHolder,
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  isRequired,
  maximLength,
  mandatory,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
    if (e.keyCode === 13) {
      const { form } = e.target;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.trim(); // Trim leading and trailing spaces
    e.target.value = value;
  };

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}
      </label>
      {mandatory || isRequired ? (
        <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
      ) : (
        <span />
      )}
      <input
        type="text"
        className={classNamess ?? className}
        placeholder={placeHolder ?? 'Email'}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        // maxLength={maximLength}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName ?? 'email', {
          required: {
            value: isRequired,
            message: `${label} is required.`,
          },
          pattern: {
            value:
              /^(?!^\.)(?!.*[_-]@)(?!.*[_-]{2})(?!.*\.\.)[a-zA-Z0-9._-]+@((([a-zA-Z0-9-]+\.)+(com|org|info|[a-zA-Z]{2,62}))|\[((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\])$/,
            message: 'Please enter a valid email with a TLD between 2 and 62 characters.',
          },
          maxLength: {
            value: maximLength,
            message: `${label} must be maximum ${maximLength}.`,
          },
        })}
      />

      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
        }}
      >
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

EmailInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  maximLength: PropTypes.number,
  labelstyle: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  classNamedark: PropTypes.string,
  labeldarkstyle: PropTypes.string,
};
EmailInput.defaultProps = {
  mandatory: false,
  isRequired: false,
  maximLength: 20,
  labeldarkstyle: null,
  classNamedark: null,
  placeHolder: 'Enter Email',
  label: 'Email',
};
export default EmailInput;
