/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import { toast } from 'react-toastify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import SelectBox from '../../components/SelectBox/SelectBox';
import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import { Entry } from '../../redux/entrySlice';
import PasswordField from '../../components/PasswordField/PasswordField';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import { TableContext } from '../../contexts/tableContext';
import styles from './createUser.module.scss';
import { REACT_APP_API_ROLES_DROPDOWN } from '../../redux/apiConstants';

function UserCreate() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add User Details');
    const url = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setRoles(resp.data.role.selectdata)); // setting response to state roles
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    if (!data.password.match(/\d/) || !data.password.match(/[a-zA-Z]/)) {
      setPerror('Password must contain at least 1 letter and 1 number');
    } else if (data.name.trim().length === 0) {
      document.getElementById('name').focus();
    }
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      dateOfBirth: moment(data.dateOfBirth).format('MM/DD/YYYY'),
      email: data.email,
      phone: data.phoneNumber,
      password: data.password,
      role: data.role.value,
      isVerified: true,
      File: files,
    };
    data.actionUrl = 'user';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('User creation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        <form className="form_wrapper" onSubmit={handleSubmit(onSubmitdata)}>
          <div
            value="Back"
            onClick={() => {
              navigate(-1);
              toast.success('User creation successfull');
            }}
            className={`${styles.arrowback} backBtn mob-back`}
          >
            <svg
              className="mr-1"
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 6.00003H3.64L7.27 1.64003C7.43974 1.43581 7.5214 1.17253 7.49702 0.908108C7.47264 0.643682 7.34422 0.39977 7.14 0.230032C6.93578 0.060293 6.6725 -0.0213689 6.40808 0.0030108C6.14365 0.0273905 5.89974 0.155815 5.73 0.360032L0.73 6.36003C0.696361 6.40776 0.666279 6.45789 0.64 6.51003C0.64 6.56003 0.64 6.59003 0.57 6.64003C0.524674 6.75469 0.500941 6.87674 0.5 7.00003C0.500941 7.12332 0.524674 7.24537 0.57 7.36003C0.57 7.41003 0.57 7.44003 0.64 7.49003C0.666279 7.54217 0.696361 7.59231 0.73 7.64003L5.73 13.64C5.82402 13.7529 5.94176 13.8437 6.07485 13.9059C6.20793 13.9681 6.35309 14.0003 6.5 14C6.73365 14.0005 6.96009 13.9191 7.14 13.77C7.24126 13.6861 7.32496 13.583 7.38631 13.4666C7.44766 13.3503 7.48546 13.223 7.49754 13.092C7.50961 12.961 7.49573 12.829 7.45669 12.7034C7.41764 12.5777 7.3542 12.4611 7.27 12.36L3.64 8.00003H15.5C15.7652 8.00003 16.0196 7.89467 16.2071 7.70714C16.3946 7.5196 16.5 7.26525 16.5 7.00003C16.5 6.73482 16.3946 6.48046 16.2071 6.29292C16.0196 6.10539 15.7652 6.00003 15.5 6.00003Z"
                fill=""
              />
            </svg>
            Back
          </div>
          <div className="form_head">
            <div
              value="Back"
              onClick={() => {
                navigate(-1);
                toast.success('User creation successfull');
              }}
              className={`${styles.arrowback} backBtn web-back`}
            >
              <svg
                className="mr-1"
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 6.00003H3.64L7.27 1.64003C7.43974 1.43581 7.5214 1.17253 7.49702 0.908108C7.47264 0.643682 7.34422 0.39977 7.14 0.230032C6.93578 0.060293 6.6725 -0.0213689 6.40808 0.0030108C6.14365 0.0273905 5.89974 0.155815 5.73 0.360032L0.73 6.36003C0.696361 6.40776 0.666279 6.45789 0.64 6.51003C0.64 6.56003 0.64 6.59003 0.57 6.64003C0.524674 6.75469 0.500941 6.87674 0.5 7.00003C0.500941 7.12332 0.524674 7.24537 0.57 7.36003C0.57 7.41003 0.57 7.44003 0.64 7.49003C0.666279 7.54217 0.696361 7.59231 0.73 7.64003L5.73 13.64C5.82402 13.7529 5.94176 13.8437 6.07485 13.9059C6.20793 13.9681 6.35309 14.0003 6.5 14C6.73365 14.0005 6.96009 13.9191 7.14 13.77C7.24126 13.6861 7.32496 13.583 7.38631 13.4666C7.44766 13.3503 7.48546 13.223 7.49754 13.092C7.50961 12.961 7.49573 12.829 7.45669 12.7034C7.41764 12.5777 7.3542 12.4611 7.27 12.36L3.64 8.00003H15.5C15.7652 8.00003 16.0196 7.89467 16.2071 7.70714C16.3946 7.5196 16.5 7.26525 16.5 7.00003C16.5 6.73482 16.3946 6.48046 16.2071 6.29292C16.0196 6.10539 15.7652 6.00003 15.5 6.00003Z"
                  fill=""
                />
              </svg>
              Back
            </div>
            <div>
              <input
                className={`${styles.resetbtn} resetBtn mr-1`}
                type="button"
                value="Reset"
                onClick={() => {
                  reset();
                }}
              />
              <span className="sub_btn">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
              </span>
            </div>
          </div>

          <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <EmailInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Email"
                    placeHolder="Enter Email Address"
                    fieldName="email"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PasswordField
                    fieldName="password"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Password"
                    register={register}
                    errors={errors}
                    placeHolder="Enter Password"
                    isRequired
                    minimLength={8}
                    eyeiconstyle={styles.eyeicon}
                    mandatory
                  />
                  {perror && <p className={styles[errormsgStyle]}>{perror}</p>}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 selectDrp">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="role"
                    label="Role"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={roles}
                    errors={errors}
                    placeholder="Choose Role"
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PhoneNumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Phone Number"
                    placeHolder="Phone Number"
                    fieldName="phoneNumber"
                    register={register}
                    errors={errors}
                    mandatory
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 dateRange">
                  <Label className={styles.formlabel}>Date of Birth</Label>
                  <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                  <DatePickerComponent
                    className={styles.dateinputbox}
                    classNamedark={styles.dateinputbox1}
                    control={control}
                    name="dateOfBirth"
                    isRequired
                    errors={errors}
                    label="Date Of Birth"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultipleFileUpload />
                </div>
              </div>

              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserCreate;
