/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Label } from 'reactstrap';
import { SpinnerDotted } from 'spinners-react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Entry } from '../../redux/entrySlice';
import SelectBox from '../../components/SelectBox/SelectBox';
import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import styles from './editUser.module.scss';
import { TableContext } from '../../contexts/tableContext';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from '../../components/TextField/TextField';
import EmailInput from '../../components/EmailBox/EmailBox';
import { REACT_APP_API_ROLES_DROPDOWN } from '../../redux/apiConstants';

const UserEdit = React.memo(() => {
  let userId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit User Details');
    userId = params.userId;
    const url2 = REACT_APP_API_ROLES_DROPDOWN; // api url
    fetch(url2)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setRoles(resp.data.role);
      }); // setting response to state posts

    const actionData = {};
    actionData.actionUrl = `user/${userId}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      setUser(resp.payload.data.user);
      reset(resp.payload.data.user);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(data) {
    if (data.name.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        dateOfBirth: moment(data.dateOfBirth).format('MM/DD/YYYY'),
        role: data.role.id ? data.role.id : data.role.value,
        isVerified: true,
        File: files,
      };
      data.actionUrl = `user/${params.userId}`;
      data.actionMethod = 'patch';
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success('User updation successfull');
          // eslint-disable-next-line max-len
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 403
        ) {
          setError(resp.payload.message);
          toast.error('Error');
        } else {
          navigate(-1);
        }
      });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        {user.length !== 0 ? (
          <form onSubmit={handleSubmit(onSubmitdata)}>
            <div className="form_head">
              <div
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={`${styles.arrowback} backBtn`}
              >
                <svg
                  className="mr-1"
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 6.00003H3.64L7.27 1.64003C7.43974 1.43581 7.5214 1.17253 7.49702 0.908108C7.47264 0.643682 7.34422 0.39977 7.14 0.230032C6.93578 0.060293 6.6725 -0.0213689 6.40808 0.0030108C6.14365 0.0273905 5.89974 0.155815 5.73 0.360032L0.73 6.36003C0.696361 6.40776 0.666279 6.45789 0.64 6.51003C0.64 6.56003 0.64 6.59003 0.57 6.64003C0.524674 6.75469 0.500941 6.87674 0.5 7.00003C0.500941 7.12332 0.524674 7.24537 0.57 7.36003C0.57 7.41003 0.57 7.44003 0.64 7.49003C0.666279 7.54217 0.696361 7.59231 0.73 7.64003L5.73 13.64C5.82402 13.7529 5.94176 13.8437 6.07485 13.9059C6.20793 13.9681 6.35309 14.0003 6.5 14C6.73365 14.0005 6.96009 13.9191 7.14 13.77C7.24126 13.6861 7.32496 13.583 7.38631 13.4666C7.44766 13.3503 7.48546 13.223 7.49754 13.092C7.50961 12.961 7.49573 12.829 7.45669 12.7034C7.41764 12.5777 7.3542 12.4611 7.27 12.36L3.64 8.00003H15.5C15.7652 8.00003 16.0196 7.89467 16.2071 7.70714C16.3946 7.5196 16.5 7.26525 16.5 7.00003C16.5 6.73482 16.3946 6.48046 16.2071 6.29292C16.0196 6.10539 15.7652 6.00003 15.5 6.00003Z"
                    fill=""
                  />
                </svg>
                Back
              </div>
              <div>
                <span className="sub_btn updateBtn">
                  <input
                    className={styles.formbtn}
                    type="submit"
                    disabled={submiting}
                    value={submiting ? 'Please wait..' : 'Update'}
                  />
                </span>
              </div>
            </div>
            <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
              <div className="container-fluid edituserform">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {user && (
                      <div>
                        <TextInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Name"
                          fieldName="name"
                          placeHolder="Enter Name"
                          register={register}
                          errors={errors}
                          isRequired
                          mandatory
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <EmailInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Email"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      placeHolder="Email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <PhoneNumberInput
                      labelstyle={styles.formlabel}
                      classNamedark={styles.inputbox1}
                      label="Phone Number"
                      placeHolder="Phone Number"
                      className={styles.inputbox}
                      fieldName="phone"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 selectDrp">
                    <SelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="role"
                      label="Role"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={roles.selectdata}
                      errors={errors}
                      placeholder="Choose Role"
                      defaultvalue={user.role.id}
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 dateRange">
                    <Label className={styles.formlabel}>Date of Birth</Label>
                    <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                    <DatePickerComponent
                      className={styles.dateinputbox}
                      classNamedark={styles.dateinputbox1}
                      control={control}
                      name="dateOfBirth"
                      isRequired
                      errors={errors}
                      label="Date Of Birth"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 col-sm-6">
                    <MultipleFileUpload editFiles={user.files} section="user" />
                  </div>
                </div>
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </div>
            </div>
          </form>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '130px', color: '#205440' }}>
            <SpinnerDotted />
          </div>
        )}
        <br />
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
});

export default UserEdit;
