// /* eslint-disable react/react-in-jsx-scope */
// // pages/refund.js
// import { useState } from 'react';

// export default function RefundPage() {
//   const [chargeId, setChargeId] = useState('ch_3PvtaGRqMlyne0vG1fdJJxbU');
//   const [amount, setAmount] = useState('2200');
//   const [status, setStatus] = useState('');

//   const handleRefund = async (event) => {
//     event.preventDefault();

//     const response = await fetch('/api/refund', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ chargeId, amount }),
//     });

//     const result = await response.json();
//     if (response.ok) {
//       setStatus(`Refund successful: ${result.id}`);
//     } else {
//       setStatus(`Refund failed: ${result.error}`);
//     }
//   };

//   return (
//     <div>
//       <h1>Refund</h1>
//       <form onSubmit={handleRefund}>
//         <label>
//           Charge ID:
//           <input
//             type="text"
//             value={chargeId}
//             onChange={(e) => setChargeId(e.target.value)}
//             required
//           />
//         </label>
//         <br />
//         <label>
//           Amount (in cents):
//           <input
//             type="number"
//             value={amount}
//             onChange={(e) => setAmount(e.target.value)}
//             required
//           />
//         </label>
//         <br />
//         <button type="submit">Refund</button>
//       </form>
//       <p>{status}</p>
//     </div>
//   );
// }
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Image, Container, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import envValues from '../../enviornment';
import { SERVER_BASE_URL } from '../../redux/apiConstants';

function ViewRefund() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const [chargeId, setChargeId] = useState('ch_3PvtaGRqMlyne0vG1fdJJxbU');
  const [amount, setAmount] = useState('2200');
  const [status, setStatus] = useState('');
  const params = useParams();
  let userId;
  const handleRefund = async (event) => {
    event.preventDefault();

    const response = await fetch(`${SERVER_BASE_URL}/user/refund`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chargeId, amount }),
    });

    const result = await response.json();
    if (response.ok) {
      setStatus(`Refund successful: ${result.id}`);
    } else {
      setStatus(`Refund failed: ${result.error}`);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    setDashboardHeader('Refund Details');
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        <form className="form_wrapper" onSubmit={handleRefund}>
          <div
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={`${styles.arrowback} backBtn mob-back`}
          >
            <svg
              className="mr-1"
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 6.00003H3.64L7.27 1.64003C7.43974 1.43581 7.5214 1.17253 7.49702 0.908108C7.47264 0.643682 7.34422 0.39977 7.14 0.230032C6.93578 0.060293 6.6725 -0.0213689 6.40808 0.0030108C6.14365 0.0273905 5.89974 0.155815 5.73 0.360032L0.73 6.36003C0.696361 6.40776 0.666279 6.45789 0.64 6.51003C0.64 6.56003 0.64 6.59003 0.57 6.64003C0.524674 6.75469 0.500941 6.87674 0.5 7.00003C0.500941 7.12332 0.524674 7.24537 0.57 7.36003C0.57 7.41003 0.57 7.44003 0.64 7.49003C0.666279 7.54217 0.696361 7.59231 0.73 7.64003L5.73 13.64C5.82402 13.7529 5.94176 13.8437 6.07485 13.9059C6.20793 13.9681 6.35309 14.0003 6.5 14C6.73365 14.0005 6.96009 13.9191 7.14 13.77C7.24126 13.6861 7.32496 13.583 7.38631 13.4666C7.44766 13.3503 7.48546 13.223 7.49754 13.092C7.50961 12.961 7.49573 12.829 7.45669 12.7034C7.41764 12.5777 7.3542 12.4611 7.27 12.36L3.64 8.00003H15.5C15.7652 8.00003 16.0196 7.89467 16.2071 7.70714C16.3946 7.5196 16.5 7.26525 16.5 7.00003C16.5 6.73482 16.3946 6.48046 16.2071 6.29292C16.0196 6.10539 15.7652 6.00003 15.5 6.00003Z"
                fill=""
              />
            </svg>
            Back
          </div>

          <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
            <label>
              Charge ID:
              <input
                type="text"
                value={chargeId}
                onChange={(e) => setChargeId(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Amount (in cents):
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </label>
            <br />
            <button type="submit">Refund</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ViewRefund;
